import {defineStore} from 'pinia';
import api from '../../api';

const state = () => ({
    entities: {
        riskAnalysisModels: [],
        saqDefinitions: [],
        siteVisitsChecklists: [],
        userGroups: [],
        categories: [],
    },
});

const actions = {
    async loadEntities(env, client_id) {
        try {
            const {
                riskAnalysisModels,
                saqDefinitions,
                siteVisitsChecklists,
                userGroups,
                categories,
            } = await api.getEditorBundle(env, client_id);
            this.entities.riskAnalysisModels = riskAnalysisModels.map(i => ({value: i.id, text: i.title}));
            this.entities.saqDefinitions = saqDefinitions.map(i => ({value: i.id, text: i.title}));
            this.entities.saqDefinitions.unshift({value: undefined, text: ''});
            this.entities.siteVisitsChecklists = siteVisitsChecklists.map(i => ({value: i.id, text: i.title}));
            this.entities.siteVisitsChecklists.unshift({value: undefined, text: ''});
            this.entities.userGroups = userGroups.map(i => ({value: i.id, text: i.name}));
            this.entities.categories = categories.filter(i => i.value).map(i => ({value: i.value, text: i.text}));
        } catch (e) {
            console.error('loadEntities', e);
        }
    }
};

const getters = {
    getEntities: state => state.entities,
};

export const useCoreStore = defineStore('coreStore', {
    state,
    getters,
    actions,
});