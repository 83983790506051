<template>
  <v-app dark id="workflow-editor">

    <v-app-bar color="primary" app dark>
      <v-toolbar-title>{{ client.company ? `Workflow – ${client.company}` : '' }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="workflow.name"
          outlined
          dense
          hide-details
          :label="$t('name')"
          class="mr-4"
      ></v-text-field>
      <v-autocomplete
          v-model="workflow.targets.groups"
          :items="entities.userGroups"
          item-text="text"
          item-value="value"
          multiple
          outlined
          dense
          hide-details
          :label="$t('target_groups')"
          class="mr-4"
      ></v-autocomplete>
      <v-toolbar-items>
        <v-btn icon v-if="isLoading" :loading="true"></v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click.stop="openJsonEditor">
              <v-icon>{{ icons.mdiCodeJson }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('edit_json') }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click.stop="dialogSave = true" :loading="isSaving">
              <v-icon>{{ icons.mdiContentSave }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('save') }}</span>
        </v-tooltip>
      </v-toolbar-items>
    </v-app-bar>

    <v-dialog v-model="dialogSave" max-width="400">
      <v-card>
        <v-card-title>{{ $t('save_warning') }}</v-card-title>
        <v-card-text>
          <div v-html="$t('save_instruction')"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogSave = false">{{ $t('button_cancel') }}
          </v-btn>
          <v-btn color="warning" text @click="save">{{ $t('button_save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogJson">
      <v-card>
        <v-card-title>{{ $t('edit_json') }}</v-card-title>
        <v-card-text>
          <vue-json-editor
              v-model="editorJson" :show-btns="false"
              :expanded-on-start="true"
              mode="tree"></vue-json-editor>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogJson = false">{{ $t('button_close') }}
          </v-btn>
          <v-btn color="success" text @click="saveJson">{{ $t('button_save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container fluid>
      <v-main>
        <v-form>
          <v-select
              v-model="workflow.ra_filter"
              :items="entities.riskAnalysisModels"
              outlined
              dense
              :label="$t('default_ra_filter_model')"
              @change="stepChanged(false)"
          ></v-select>
          <div id="steps">
            <div class="step"
                 v-for="(step, index) of steps"
                 :key="index">
              <drop class="drop mb-7"
                    :class="{ over }"
                    @dragover="over = true"
                    @dragleave="over = false"
                    @drop="handleDrop(index, ...arguments)">
                <drag :transfer-data="{index: index}">
                  <v-card>
                    <v-card-text>
                      <step
                          :step="step"
                          :steps="steps"
                          :index="index"
                          :key="`${step.id}-${updateKey}`"
                          :entities="entities"
                          @changed="stepChanged"
                          @delete="deleteStep"
                      ></step>
                    </v-card-text>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            color="info"
                            fab
                            small
                            absolute
                            bottom
                            right
                            v-on="on"
                            @click="addStep(index, 'check')"
                        >
                          <v-icon>{{ icons.mdiPlus }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('add_step') }}</span>
                    </v-tooltip>
                  </v-card>
                </drag>
              </drop>
            </div>
            <div v-if="steps.length === 0" class="d-flex justify-end">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                      color="info"
                      fab
                      small
                      v-on="on"
                      @click="addStep(-1, 'check')"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('add_step') }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-form>
      </v-main>
    </v-container>

    <v-snackbar
        v-model="snackbar"
        :timeout="timeout">
      {{ snackbarMessage }}
    </v-snackbar>

  </v-app>
</template>

<style>
.drop.over .v-card {
  background-color: #f7fafc;
}
</style>

<script>
import api from './api';
import short from './shortuid';
import {
  mdiCodeJson,
  mdiContentSave,
  mdiDelete,
  mdiPlus,
} from '@mdi/js';
import Step from './components/Step';
import {Drag, Drop} from 'vue-drag-drop';
import VueJsonEditor from 'vue-json-editor';
import '@/assets/css/vue-json-editor-overrides.css';
import _cloneDeep from 'lodash/cloneDeep';
import cookies from 'js-cookie';
import {useCoreStore} from './stores/core';
import {mapState} from 'pinia';

export default {
  name: 'Editor',
  setup() {
    const coreStore = useCoreStore();
    return {coreStore};
  },
  created() {
    if (!cookies.get('ev-app-t')) {
      this.$router.push('/');
    }
    this.start();
  },
  props: {
    clientId: String,
    workflowId: String,
  },
  components: {
    Step,
    Drag,
    Drop,
    VueJsonEditor,
  },
  watch: {
    isChanged() {
      if (this.isChanged) {
        window.onbeforeunload = function () {
          return true;
        };
      } else {
        window.onbeforeunload = null;
      }
    }
  },
  computed: {
    ...mapState(useCoreStore, {
      entities: 'getEntities',
    }),
  },
  data: () => ({
    client: {},
    workflow: {},
    steps: [],
    editorJson: [],
    over: false,
    updateKey: 0,
    dialogSave: false,
    dialogJson: false,
    isLoading: false,
    isSaving: false,
    isChanged: false,
    isError: false,
    timeout: 3000,
    snackbar: false,
    snackbarMessage: '',
    idRule: [
      v => !!v || 'Required'
    ],
    icons: {
      mdiContentSave,
      mdiPlus,
      mdiDelete,
      mdiCodeJson,
    },
  }),
  methods: {
    openJsonEditor() {
      this.editorJson = _cloneDeep(this.steps);
      this.dialogJson = true;
    },
    saveJson() {
      this.steps = this.editorJson;
      this.updateKey += 1;
      this.isChanged = true;
      this.dialogJson = false;
    },
    handleDrop(target, data) {
      this.isLoading = true;
      this.over = false;
      const source = data.index;
      if (source !== target) {
        this.steps.splice(target, 0, this.steps.splice(source, 1)[0]);
        this.isChanged = true;
        this.updateKey += 1;
      }
      this.isLoading = false;
    },
    async start() {
      this.isLoading = true;
      if (this.clientId) {
        try {
          this.client = await api.getClient(this.clientId);
          if (this.client.id) {
            await this.coreStore.loadEntities(this.client.env, this.client.id);
            const workflow = Array.isArray(this.client.workflows) ? this.client.workflows.find(i => i.id === this.workflowId) : undefined;
            this.client.workflows = Array.isArray(this.client.workflows) ? this.client.workflows : [];
            if (!workflow) {
              let newStep = this.newStep('check');
              this.workflow = {
                id: this.workflowId,
                default: !(Array.isArray(this.client.workflows) && this.client.workflows.some(i => i.default)),
                steps: [newStep],
              };
            } else {
              this.workflow = _cloneDeep(workflow);
            }
            if (!Array.isArray(this.workflow.steps)) {
              this.workflow.steps = [];
            }
            this.workflow.targets ??= {};
            const steps = this.workflow.steps;
            steps.forEach(step => {
              step.labels ??= {};
              step.escalation ??= {};
              step.automation ??= {};
              step.filters ??= {};
              step.filters.risk ??= {excluding: false, values: []};
              step.filters.risk.models ??= [];
              step.filters.rating ??= {excluding: false, values: []};
              step.filters.status ??= {excluding: false, values: []};
              step.filters.category ??= {excluding: false, values: []};
              step.filters.group ??= {excluding: false, values: []};
              step.stopper_texts ??= {};
              step.escalation_texts ??= {};

              // TRANSFORMATION FROM OLDER MODEL
              if (step.type === 'riskanalysis') step.type = 'risk-analysis';
              if (step.type === 'sitevisit') step.type = 'site-visit';
              if (step.filter) {
                step.filters.risk.values = step.filter;
                step.filters.risk.excluding = false;
                delete step.filter;
              }
              if (step.risk_filter) {
                step.filters.risk.values = step.risk_filter;
                step.filters.risk.excluding = true;
                delete step.risk_filter;
              }
              if (step.rating_filter) {
                step.filters.rating.values = step.rating_filter;
                step.filters.rating.excluding = true;
                delete step.rating_filter;
              }
              if (step.status_filter) {
                step.filters.status.values = step.status_filter;
                step.filters.status.excluding = false;
                delete step.status_filter;
              }
              if (step.filters.risk.model) {
                step.filters.risk.models = step.filters.risk.model.split(',');
                delete step.filters.risk.model;
              }
            });
            this.steps = steps;
          }
        } catch {
          this.isError = true;
        }
      } else {
        this.isError = true;
      }
      this.isLoading = false;
    },
    addStep(index, type) {
      let newStep = this.newStep(type);
      const steps = _cloneDeep(this.steps);
      if (steps.length > index) {
        steps.splice(index + 1, 0, newStep);
      } else {
        steps.push(newStep);
      }
      this.steps = steps;
      this.updateKey += 1;
      this.isChanged = true;
    },
    newStep(type) {
      return {
        id: short.generateRandomBase(),
        type,
        manual: true,
        labels: {
          en: '',
          sv: '',
        },
        escalation: {},
        automation: {},
        filters: {
          risk: {excluding: false, values: [], models: []},
          rating: {excluding: false, values: []},
          status: {excluding: false, values: []},
          category: {excluding: false, values: []},
          group: {excluding: false, values: []},
        },
        stopper_texts: {},
        escalation_texts: {},
      };
    },
    stepChanged(index, step) {
      this.$set(this.steps, index, step);
    },
    deleteStep(index) {
      const steps = _cloneDeep(this.steps);
      steps.splice(index, 1);
      this.steps = steps;
      this.updateKey += 1;
      this.isChanged = true;
    },
    cleanUpSteps() {
      const steps = _cloneDeep(this.steps);
      for (const step of steps) {
        if (step.filters.risk.values.length === 0) {
          delete step.filters.risk;
        }
        if (step.filters.rating.values.length === 0) {
          delete step.filters.rating;
        }
        if (step.filters.status.values.length === 0) {
          delete step.filters.status;
        }
        if (step.filters.category.values.length === 0) {
          delete step.filters.category;
        }
        if (Object.keys(step.escalation).length === 0) {
          delete step.escalation;
        }
      }
      return steps;
    },
    async save() {
      this.dialogSave = false;
      this.isSaving = true;
      this.workflow.steps = this.cleanUpSteps(this.steps);
      this.workflow.ts_added ??= new Date();
      this.workflow.ts_changed = new Date();
      this.isChanged = false;
      const index = this.client.workflows.findIndex(i => i.id === this.workflow.id);
      if (index > -1) {
        this.client.workflows[index] = this.workflow;
      } else {
        this.client.workflows.push(this.workflow);
      }
      const result = await api.updateClientWorkflows(this.clientId, this.client.workflows);
      this.isSaving = false;
      if (result.success) {
        if (window.opener && window.opener.location) {
          // window.opener.location.reload();
        }
        this.snackbarMessage = 'Saved successfully';
        this.snackbar = true;
      } else {
        this.snackbarMessage = 'Could not save';
        this.snackbar = true;
      }
    },
  }
};

</script>
