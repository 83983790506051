import Vue from 'vue';
import VueRouter from 'vue-router';
import Blank from '../Blank';
import Editor from '../Editor';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'blank',
        component: Blank
    },
    {
        path: '/:clientId/:workflowId',
        name: 'editor',
        component: Editor,
        props: true,
    },
];

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router
