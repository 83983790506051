import short from 'shortid';

const base36Timestamp = () => Math.floor((new Date().getTime() - new Date(2022, 1, 1)) / 60000).toString(36);
const generateTimeBase = () => base36Timestamp().toLowerCase();
const generateRandomBase = () => short.generate().replace(/[_-]/g, '').toLowerCase();
const generate = () => `${generateRandomBase()}${generateTimeBase()}`;

export default {
    generate,
    generateTimeBase,
    generateRandomBase,
};