import axios from 'axios';
import cookies from 'js-cookie';

const coreBaseUrl = (window.location.hostname === 'localhost') ? 'http://localhost:3005/core-api' : '/core-api';
const insideBaseUrl = (window.location.hostname === 'localhost') ? 'http://localhost:3005/inside-api' : '/inside-api';

const getData = async (path, isCore, isEnv) => {
    const env = cookies.get('ev-env') || 'p';
    const url = `${isCore ? coreBaseUrl : insideBaseUrl}${isEnv ? '/' + env : ''}${path}`;
    try {
        const config = {
            headers: {
                'Authorization': `Bearer ${cookies.get('ev-app-t')}`,
            }
        };
        const response = await axios.get(url, config);
        return response.data;
    } catch (error) {
        console.error('api: getData', url, error);
        return [];
    }
};

const postData = async (path, data, isCore, isEnv) => {
    const env = cookies.get('ev-env') || 'p';
    const url = `${isCore ? coreBaseUrl : insideBaseUrl}${isEnv ? '/' + env : ''}${path}`;
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${cookies.get('ev-app-t')}`,
            }
        };
        const response = await axios.post(url, data, config);
        return response.data;
    } catch (error) {
        console.error('api: postData', url, error);
        return [];
    }
};

const getClient = async (client_id) => {
    let response = {};
    try {
        response = await getData(`/v1/admin/clients/${client_id}`, false);
    } catch (error) {
        console.error('api: getClient', error);
    }
    return response;
};

const getEditorBundle = async (env, client_id) => {
    let response = {};
    try {
        response = await getData(`/v1/admin/workflow/editor-bundle/${env}/${client_id}`, false);
    } catch (error) {
        console.error('api: getEditorBundle', error);
    }
    return response;
};

const updateClientWorkflows = async (client_id, workflows) => {
    let response = {
        success: false
    };
    try {
        response = await postData(`/v1/admin/clients/${client_id}`, {workflows}, false);
    } catch (error) {
        console.error('api: updateClientWorkflow', error);
    }
    return response;
};

export default {
    getClient,
    updateClientWorkflows,
    getEditorBundle,
};
